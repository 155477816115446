<template>
  <div class="page">
    <el-container>
      <el-header>
        <Head ></Head>
      </el-header>
      <el-main>
        <div class="wrap">
          <ul class="bread-crumb">
                <li class="item item-1 fill">
                     {{$t('report.post') + $t('report.upload')}}
                </li>
                <li class="item item-2">
                     {{$t('report.post') + $t('report.scale')}}
                </li>
                <li class="item item-3">
                     {{$t('report.post') + $t('report.level')}}
                </li>
                <li class="item item-3">
                     {{$t('report.post') + $t('report.measure')}}
                </li>
                <li class="item item-3">
                     {{$t('report.post') + $t('report.install')}}
                </li>
                 <li class="item item-4">
                     {{$t('report.post') + $t('report.stand')}}
                </li>
                <li class="item item-5">
                     {{$t('report.post') + $t('report.create')}}
                </li>
            </ul>
          <div class="main upload">
            <div class="front">
              <h2 class="">{{$t('report.front')}}</h2>
              <div>
                <el-upload
                  class="upload-demo"
                  drag
                  accept="image/*"
                  :multiple="false"
                  :show-file-list="false"
                  :on-preview="handleFrontPreview"
                  :on-success="handleFrontChange"
                  :on-remove="handleFrontRemove"
                  :file-list="frontFileList"
                  :before-upload="beforeUpload"
                  :action="$baseUrl + '/dabo/upload'"
                >
                  <div class="upload-box" v-if="!frontFileList.length">
                    <div class="btn-upload">{{$t('report.btnUpload')}}</div>
                    <p style="margin: 30px 0 18px 0;">{{$t('report.text1')}}</p>
                    <p>{{$t('report.text2')}}</p>
                  </div>
                  <div class="preview" v-else>
                    <img :src="frontFileList[0].data.fullurl" alt="" />
                  </div>
                </el-upload>
              </div>
            </div>
            <div class="side">
              <h2 class="">{{$t('report.side')}}</h2>
              <div>
                <el-upload
                  class="upload-demo"
                  drag
                  accept="image/*"
                  :show-file-list="false"
                  :multiple="false"
                  :on-preview="handleSidePreview"
                  :on-success="handleSideChange"
                  :on-remove="handleSideRemove"
                  :file-list="sideFileList"
                  :before-upload="beforeUpload"
                  :action="$baseUrl + '/dabo/upload'"
                >
                  <div class="upload-box" v-if="!sideFileList.length">
                    <div class="btn-upload">{{$t('report.btnUpload')}}</div>
                    <p style="margin: 30px 0 18px 0;">{{$t('report.text1')}}</p>
                    <p>{{$t('report.text2')}}</p>
                  </div>
                  <div class="preview" v-else>
                    <img :src="sideFileList[0].data.fullurl" alt="" />
                  </div>
                </el-upload>
              </div>
            </div>
            <div class="btn-submit" @click="saveImg">{{$t('common.submit')}}</div>
            <div class="btn-skip" @click="skipImg">{{$t('common.skip')}}</div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: "", //病例id
      sideFileList: [], //侧位图
      frontFileList: [], //正位图
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.$api.getReport({
            before_after: 1,
            id: this.id
        }).then(res => {
            if(res.code && res.data.base.length){
                if(res.data.base[0].image){
                    let front = {
                        fullurl: this.$baseUrl + res.data.base[0].image,
                        url: res.data.base[0].image
                    }
                    this.frontFileList.push({data: front})
                }
                if(res.data.base[1].image){
                    let side = {
                        fullurl: this.$baseUrl + res.data.base[1].image,
                        url: res.data.base[1].image
                    }
                    this.sideFileList.push({data: side})
                }         
            }
        })
    } else {
      this.$message.error(this.$t('common.errMsg2'));
      setTimeout((res) => {
        this.$router.go(-1);
      }, 500);
    }
  },
  methods: {
    //上传文件类型判断
    beforeUpload(file){
   
            var testmsg=file.name.substring(file.name.lastIndexOf('.')+1)
            const extension = testmsg === 'jpg';
            const extension2 = testmsg === 'png';
            const extension3 = testmsg === 'bmp';
            const extension4 = testmsg === 'jpeg';
            if(!extension && !extension2 && !extension3 && !extension4) {
                this.$message({
                    message: '上传文件只能是 jpg、png、bmp格式!',
                    type: 'warning'
                });
            }
            return extension || extension2 || extension3 || extension4

    },
    //跳过
    skipImg(){
      this.$router.push("/post/measure?id=" + this.id);
    },
    //提交图片
    saveImg() {
      if (!this.frontFileList.length) {
        return this.$message.error(this.$t('message1'));
      }
      if (!this.sideFileList.length) {
        return this.$message.error(this.$t('message2'));
      }

      this.$api
        .saveImage({
          image: [
            this.frontFileList[0].data.url,
            this.sideFileList[0].data.url,
          ].join(),
          id: this.id,
          before_after: 1,
        })
        .then((res) => {
          //比例测量
          if (res.code) {
            return this.$router.push("/post/scale?id=" + this.id);
          }
          this.$message.error(res.msg);
        });
    },
    handleSideChange(file, fileList) {
      // this.sideFileList = fileList.slice(-3);
      this.sideFileList = [file];
      console.log(this.sideFileList);
    },
    handleSideRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleSidePreview(file) {
      console.log(file);
    },
    handleFrontChange(file, fileList) {
      this.frontFileList = [file];
      console.log(this.frontFileList);
    },
    handleFrontRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleFrontPreview(file) {
      console.log(file);
    },
    
  },
};
</script>
<style scoped>
.page {
  background: #f1f5f7;
}
.el-main {
  padding: 0;
}
.head {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.el-header {
  background: #ffffff;
  text-align: right;
}
.btn-logout {
  display: inline-block;
  padding: 8px 17px;
  padding-left: 37px;
  background: #f1f5f7;
  border: 1px solid #364284;
  border-radius: 14px;
  color: #364284;
  margin-right: 50px;
  position: relative;
}
.btn-logout::before {
  content: "";
  background: url(../../assets/img/off.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translate(0%, -50%);
}
.btn-logout:hover {
  cursor: pointer;
}

.main {
  /* width: 100%; */
  height: 599px;
  border-radius: 4px;
  background: #ffffff;
  padding: 0 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.main h2 {
  font-size: 23px;
  font-weight: bold;
  color: #364284;
  margin-bottom: 30px;
}

.upload-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  color: #686868;
}
.btn-upload {
  width: 198px;
  height: 49px;
  line-height: 49px;
  text-align: center;
  background: #7ecdf6;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}
.main .btn-submit {
  position: absolute;
  bottom: 25px;
  left: 50%;
  margin: 15px;
  /* transform: translate(-50%, 0); */
}
.main .btn-skip {
  background: #F1F4F8;
  color: #364284;
  position: absolute;
  bottom: 25px;
  right: 50%;
  margin: 15px;
  /* transform: translate(-50%, 0); */
}
.preview img {
  width: 100%;
  height: auto;
}
</style>
